<template>
  <v-card class="card-shadow border-radius-xl">
    <div class="px-6 py-6">
      <h5 class="text-h5 font-weight-bold text-typo">{{ $t("Mark") }}</h5>
    </div>
    <div class="px-6 pb-6 pt-0">
      <v-row>
        <v-col cols="3">
          <label class="text-xs text-typo font-weight-bolder ms-1">{{
            $t("city")
          }}</label>
          <v-text-field
            v-model="markData.縣市"
            hide-details
            outlined
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <label class="text-xs text-typo font-weight-bolder ms-1">{{
            $t("district")
          }}</label>
          <v-text-field
            v-model="markData.鄉鎮市區"
            hide-details
            outlined
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <label class="text-xs text-typo font-weight-bolder ms-1">{{
            $t("region")
          }}</label>
          <v-text-field
            v-model="markData.地段"
            hide-details
            outlined
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3" v-if="isLand()">
          <label class="text-xs text-typo font-weight-bolder ms-1">{{
            $t("land-nos")
          }}</label>
          <v-text-field
            v-model="markData.地號"
            hide-details
            outlined
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3" v-else>
          <label class="text-xs text-typo font-weight-bolder ms-1">{{
            $t("building-nos")
          }}</label>
          <v-text-field
            v-model="markData.建號"
            hide-details
            outlined
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <label class="text-xs text-typo font-weight-bolder ms-1">{{
            $t("area")
          }}</label>
          <v-text-field
            v-model="isLand() ? markData.面積 : markData.總面積"
            hide-details
            outlined
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <label class="text-xs text-typo font-weight-bolder ms-1">{{
            $t("reg_date_str")
          }}</label>
          <v-text-field
            v-model="markData.登記日期"
            hide-details
            outlined
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <label class="text-xs text-typo font-weight-bolder ms-1">{{
            $t("reason")
          }}</label>
          <v-text-field
            v-model="markData.登記原因"
            hide-details
            outlined
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="isLand()">
        <v-col cols="2">
          <label class="text-xs text-typo font-weight-bolder ms-1">{{
            $t("zone")
          }}</label>
          <v-text-field
            v-model="markData.使用分區"
            hide-details
            outlined
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          >
          </v-text-field>
        </v-col>

        <v-col cols="2">
          <label class="text-xs text-typo font-weight-bolder ms-1">{{
            $t("land_class")
          }}</label>
          <v-text-field
            v-model="markData.使用地類別"
            hide-details
            outlined
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          >
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <label class="text-xs text-typo font-weight-bolder ms-1">{{
            $t("al_price")
          }}</label>
          <v-text-field
            v-model="markData.公告土地現值"
            hide-details
            outlined
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          >
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <label class="text-xs text-typo font-weight-bolder ms-1">{{
            $t("al_price_date")
          }}</label>
          <v-text-field
            v-model="markData.公告現值年月"
            hide-details
            outlined
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          >
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <label class="text-xs text-typo font-weight-bolder ms-1">{{
            $t("al_value")
          }}</label>
          <v-text-field
            v-model="markData.公告地價"
            hide-details
            outlined
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          >
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <label class="text-xs text-typo font-weight-bolder ms-1">{{
            $t("al_value_date")
          }}</label>
          <v-text-field
            v-model="markData.公告地價年月"
            hide-details
            outlined
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <label class="text-xs text-typo font-weight-bolder ms-1">{{
            $t("buildings")
          }}</label>
          <v-text-field
            v-model="markData.地上建物建號"
            hide-details
            outlined
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="2">
          <label class="text-xs text-typo font-weight-bolder ms-1">{{
            $t("land-nos")
          }}</label>
          <v-text-field
            v-model="markData.主要建材"
            hide-details
            outlined
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          >
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <label class="text-xs text-typo font-weight-bolder ms-1">{{
            $t("land-nos")
          }}</label>
          <v-text-field
            v-model="markData.主要用途"
            hide-details
            outlined
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          >
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <label class="text-xs text-typo font-weight-bolder ms-1">{{
            $t("land-nos")
          }}</label>
          <v-text-field
            v-model="markData.層數"
            hide-details
            outlined
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          >
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <label class="text-xs text-typo font-weight-bolder ms-1">{{
            $t("land-nos")
          }}</label>
          <v-text-field
            v-model="markData.建物坐落地號"
            hide-details
            outlined
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          >
          </v-text-field>
        </v-col>

        <v-col cols="2">
          <label class="text-xs text-typo font-weight-bolder ms-1">{{
            $t("door_map")
          }}</label>
          <v-text-field
            v-model="markData.建物門牌"
            hide-details
            outlined
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          >
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <label class="text-xs text-typo font-weight-bolder ms-1">{{
            $t("completion_date")
          }}</label>
          <v-text-field
            v-model="markData.建築完成日期"
            hide-details
            outlined
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" v-if="isLand()">
          <label class="text-xs text-typo font-weight-bolder ms-1">{{
            $t("remark")
          }}</label>
          <v-textarea :value="markData.地價備註事項" hide-details outlined>
          </v-textarea>
        </v-col>
        <v-col cols="6" v-else>
          <label class="text-xs text-typo font-weight-bolder ms-1">{{
            $t("remark")
          }}</label>
          <v-textarea
            :value="get_common_parts(markData.共有部份)"
            hide-details
            outlined
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          >
          </v-textarea>
        </v-col>
        <v-col cols="6">
          <label class="text-xs text-typo font-weight-bolder ms-1">{{
            $t("other_reg")
          }}</label>
          <v-textarea
            :value="markData.其他登記事項"
            hide-details
            outlined
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          >
          </v-textarea>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>
<script>
export default {
  name: "mark-info",
  props: {
    lbtype: {
      type: String,
    },
    markData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  methods: {
    isLand() {
      const isLand = this.lbtype == "L";
      return isLand;
    },
    get_common_parts(data) {
      if (data) {
        let msg = "";
        data.forEach((item) => {
          msg += JSON.stringify(item) + "\n";
        });
        return msg;
      }
      return data;
    },
  },
};
</script>
