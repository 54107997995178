<template>
  <div></div>
</template>
<script>
export default {
  name: "notifcations",
  data() {
    return {
      snackbar: {
        color: null,
        visible: false,
      },
      snackbars: [
        {
          color: "#2dce89",
          class: "success",
          name: "Success",
        },
        {
          color: "#11cdef",
          class: "info",
          name: "Info",
        },
        {
          color: "#fb6340",
          class: "warning",
          name: "Warning",
        },
        {
          color: "#f5365c",
          class: "danger",
          name: "Danger",
        },
      ],
      alerts: [
        {
          type: "secondary",
        },
        {
          type: "primary",
        },
        {
          type: "info",
        },
        {
          type: "success",
        },
        {
          type: "danger",
        },
        {
          type: "warning",
        },
        {
          type: "dark",
        },
      ],
    };
  },
  methods: {
    SnackbarShow(type, message) {
      if (!type) return;
      switch (type) {
        case "Info":
          this.snackbar = {
            color: "#17c1e8",
            visible: true,
            message: message,
          };
          break;
        case "Success":
          this.snackbar = {
            color: "#82d616",
            visible: true,
            message: message,
          };
          break;
        case "Warning":
          this.snackbar = {
            color: "#fbcf33",
            visible: true,
            message: message,
          };
          break;
        case "Danger":
          this.snackbar = {
            color: "#ea0606",
            visible: true,
            message: message,
          };
          break;
      }
    },
  },
};
</script>
